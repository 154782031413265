import React from 'react'
import styled from 'styled-components'
import { Row } from 'components/common/layout'
import { BoldText, MediumText } from 'components/common/text'
import {
  ScoutRequestFilterAssessment,
  ScoutRequestFilterAssessmentSectionKey,
  ScoutRequestFilterAssessmentSections,
  ScoutRequestFilterAssessmentSectionsEnum,
  WithTotalMatched,
} from 'types/common'
import { FiCheck } from 'react-icons/fi'
import { theme } from 'infra/theme'
import { useIsDesktopCSR } from 'utils/hooks'

const CheckIcon: React.FC<{ isActive: boolean }> = ({ isActive }) => (
  <Row style={{ width: 22 }}>
    <FiCheck
      size={16}
      color={isActive ? theme.color.primary : theme.color.gray3}
      strokeWidth={4}
    />
  </Row>
)

export const MatchedText: React.FC<{ matched: number; total: number }> = ({
  matched,
  total,
}) => (
  <SmallBoldText style={{ width: 22 }}>
    <span>{matched}</span>/<span>{total}</span>
  </SmallBoldText>
)

const renderContent = (
  key: ScoutRequestFilterAssessmentSectionKey,
  sections: ScoutRequestFilterAssessmentSections,
) => {
  const item = sections[key]
  if (typeof item === 'boolean') {
    return { content: <CheckIcon isActive={item} />, active: item }
  } else {
    const { matched, total } = item as WithTotalMatched
    const isActive = key === 'positions' && total >= 4
    const content = isActive ? (
      <CheckIcon isActive={true} />
    ) : (
      <MatchedText matched={matched} total={total} />
    )
    return { content, active: isActive }
  }
}

export const FilterAssessment: React.FC<{
  data: ScoutRequestFilterAssessment
  isNarrow?: boolean
}> = ({ data, isNarrow }) => {
  const isDesktop = useIsDesktopCSR()
  const _isNarrow = !isDesktop || !!isNarrow
  const sectionKeys = Object.keys(
    data.sections,
  ) as ScoutRequestFilterAssessmentSectionKey[]

  return (
    <>
      {sectionKeys.map((key) => {
        const { content, active } = renderContent(key, data.sections)
        return (
          <StyledRow key={key}>
            <Row>{content}</Row>
            <ItemText isNarrow={_isNarrow} active={active}>
              {ScoutRequestFilterAssessmentSectionsEnum[key]}
            </ItemText>
          </StyledRow>
        )
      })}
    </>
  )
}

const ItemText = styled(MediumText)<{ isNarrow: boolean; active: boolean }>`
  color: ${(p) => (p.active ? p.theme.color.black2 : p.theme.color.gray2)};
`

const SmallBoldText = styled(BoldText)`
  font-size: ${(p) => p.theme.fontSize.small};
  & :first-child {
    color: ${(p) => p.theme.color.primary};
  }
  color: ${(p) => p.theme.color.gray2};
`

const StyledRow = styled(Row)`
  gap: 8px;
`
