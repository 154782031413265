import React from 'react'
import styled from 'styled-components'
import { Column, Row } from 'components/common/layout'
import {
  ScoutRequestAiAssessment,
  ScoutRequestAiAssessmentSection,
  ScoutRequestAiAssessmentSectionType,
} from 'types/common'
import { SemiBoldText } from 'components/common/text'
import { Image } from 'components/common/image'
import { AssessmentDescText } from 'components/dashboard/scouts/profile/styles'

const evaluateScore = (score: number, maxScore: number) => {
  const percentage = (score / maxScore) * 100
  if (percentage >= 85) {
    return 'good'
  } else if (percentage < 35) {
    return 'not'
  } else {
    return 'potential'
  }
}

const SECTION_TITLE_MAP: Record<
  ScoutRequestAiAssessmentSectionType,
  string | null
> = {
  CUSTOM: null,
  QUALIFICATION: '자격요건',
  PREFERRED: '우대사항',
}

const AiAssessmentDescriptionList: React.FC<{
  sections: ScoutRequestAiAssessmentSection[]
  isNarrow?: boolean
}> = ({ sections, isNarrow }) => {
  const sectionTitle = sections[0]?.type
    ? SECTION_TITLE_MAP[sections[0].type]
    : null
  return (
    <Column style={{ gap: isNarrow ? 8 : 32 }}>
      {sectionTitle && <TitleText>{sectionTitle}</TitleText>}
      {sections.map((x) => (
        <Item key={x.description}>
          <Row style={{ flex: 'none' }}>
            <Image
              src={`/${evaluateScore(x.score, x.maxScore)}-match-square.svg`}
              alt='match-square'
              width={isNarrow ? 20 : 24}
              height={isNarrow ? 20 : 24}
            />
          </Row>
          <Column style={{ gap: isNarrow ? 0 : 8 }}>
            <DescriptionText isNarrow={isNarrow}>
              {x.description}
            </DescriptionText>
            {!isNarrow && <AssessmentDescText>{x.reason}</AssessmentDescText>}
          </Column>
        </Item>
      ))}
    </Column>
  )
}

export const AiAssessmentDescription: React.FC<{
  data: ScoutRequestAiAssessment
  isNarrow?: boolean
}> = ({ data, isNarrow }) => {
  const qualifications = data.sections.filter((x) => x.type === 'QUALIFICATION')
  const preferred = data.sections.filter((x) => x.type === 'PREFERRED')
  const custom = data.sections.filter((x) => x.type === 'CUSTOM')
  return (
    <>
      {qualifications.length > 0 && (
        <AiAssessmentDescriptionList
          sections={qualifications}
          isNarrow={isNarrow}
        />
      )}
      {preferred.length > 0 && (
        <AiAssessmentDescriptionList sections={preferred} isNarrow={isNarrow} />
      )}
      {
        /* 현재 qualifications, preferred가 있는 경우에는 custom은 없음 */
        custom.length > 0 && (
          <AiAssessmentDescriptionList sections={custom} isNarrow={isNarrow} />
        )
      }
    </>
  )
}

const Item = styled(Row)`
  gap: 8px;
  align-items: flex-start;
`

const TitleText = styled.span`
  color: #777a88;
  font-size: 14px;
`

const DescriptionText = styled(SemiBoldText)<{ isNarrow?: boolean }>`
  font-size: ${(p) => p.isNarrow && p.theme.fontSize.small};
  line-height: ${(p) => (p.isNarrow ? '20px' : '22px')};
`
